exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bem-vindo-js": () => import("./../../../src/pages/bem-vindo.js" /* webpackChunkName: "component---src-pages-bem-vindo-js" */),
  "component---src-pages-controle-de-estoques-js": () => import("./../../../src/pages/controle-de-estoques.js" /* webpackChunkName: "component---src-pages-controle-de-estoques-js" */),
  "component---src-pages-controle-de-vendas-e-pdv-js": () => import("./../../../src/pages/controle-de-vendas-e-pdv.js" /* webpackChunkName: "component---src-pages-controle-de-vendas-e-pdv-js" */),
  "component---src-pages-gerencie-multi-empresas-js": () => import("./../../../src/pages/gerencie-multi-empresas.js" /* webpackChunkName: "component---src-pages-gerencie-multi-empresas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-precos-js": () => import("./../../../src/pages/precos.js" /* webpackChunkName: "component---src-pages-precos-js" */),
  "component---src-pages-recursos-js": () => import("./../../../src/pages/recursos.js" /* webpackChunkName: "component---src-pages-recursos-js" */),
  "component---src-pages-sistema-financeiro-js": () => import("./../../../src/pages/sistema-financeiro.js" /* webpackChunkName: "component---src-pages-sistema-financeiro-js" */),
  "component---src-pages-usar-js": () => import("./../../../src/pages/usar.js" /* webpackChunkName: "component---src-pages-usar-js" */)
}

